import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

const Video = () => {
  const history = useHistory();

  useEffect(() => {
    const ytPlayBtn = document.querySelector('btn.ytp-button');
    console.log(ytPlayBtn);
  }, [])

  const videoContRef = useRef(null);
  const videoRef = useRef(null);
  
  return (
    <div className="home-page-video">
      <div className="video-container">
        <div className="video-wrapper" ref={videoContRef}>

        
        <div className="yt-cover" onClick={(e) => {
          videoContRef.current.innerHTML = `<iframe
            className="video"
            src="https://www.youtube.com/embed/extyMTdMsy8?autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>`;
        }}></div>
        </div>
      </div>
      <div className="intro-text-container">
        <div className="intro-text-heading">
          Everything will be <span style={{fontWeight: '700'}}>smart</span> in Pakistan
        </div>
        <div className="intro-text-desc">
          Optimize your home devices for a smart lifestyle tailored to your needs. Enjoy a customized home with innovative Pintar App - compatible devices that simplify your day-to-day life.
        </div>
        <div className="video-sec-btn" onClick={() => {
          history.push("/contact-us");
          window.scrollTo(0, 0);
        }}>
          Free Online Valuation - Contact Us
        </div>
      </div>
    </div>
  );
};

export default Video;
